<template>
  <div>
    <v-container class="container" fluid>
      <GsDataTable
        ref="table"
        api=""
        endpoint="/handlers"
        :appSetting="appSettingKey"
        :headers="headers"
        :default-sort-by="['name']"
        :default-sort-desc="[false]"
        :beforeCallApi="beforeCallApi"
        :afterCallApi="afterCallApi"
        @after-refresh="afterRefresh"
      >
        <template v-slot:item.actions="{ item }">
          <GsActionsMenu
            :actions="actions"
            :onActivate="actionId => action_activate(actionId, item)"
          />
        </template>
        <template v-slot:item.eventTypeId="{ item }">
          {{ item.eventTypeId._uri }}
        </template>
        <template v-slot:item.params="{ item }">
          <div
            v-if="item.params"
            class="link-like"
            @click="showObject(item.params)"
          >
            ...
          </div>
        </template>
        <template v-slot:item.eventPayloadResolver="{ item }">
          <div
            v-if="item.eventPayloadResolver"
            class="link-like"
            @click="showList1(item.eventPayloadResolver)"
          >
            {{ item.eventPayloadResolver.length }} items ...
          </div>
        </template>
        <template v-slot:item.tasks="{ item }">
          <div
            v-if="item.tasks"
            class="link-like"
            @click="showList2(item.tasks)"
          >
            {{ item.tasks.length }} items ...
          </div>
        </template>
      </GsDataTable>
    </v-container>
    <!-- object viewer -->
    <v-dialog max-width="600" persistent v-model="openDialog">
      <GsObjectDisplay :value="objectToShow" @close="openDialog = false" />
    </v-dialog>
    <!-- list viewer 1 -->
    <v-dialog max-width="600" persistent v-model="openDialog2">
      <GsObjectListDisplay2 :value="listToShow1" @close="openDialog2 = false" />
    </v-dialog>
    <!-- list viewer 2 -->
    <v-dialog max-width="600" persistent v-model="openDialog3">
      <GsObjectListDisplay :value="listToShow2" @close="openDialog3 = false" />
    </v-dialog>
  </div>
</template>

<script>
import { GsDataTable, GsActionsMenu } from "ngt-frontend-core";
import GsObjectDisplay from "@/components/EventListener/FieldEditors/GsObjectDisplay";
import GsObjectListDisplay from "@/components/EventListener/FieldEditors/GsObjectListDisplay";
import GsObjectListDisplay2 from "@/components/EventListener/FieldEditors/GsObjectListDisplay2";

export default {
  name: "GsEventHandlersTable",
  components: {
    GsDataTable,
    GsActionsMenu,
    GsObjectDisplay,
    GsObjectListDisplay,
    GsObjectListDisplay2
  },
  props: {
    clientIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      appSettingKey: "eventHandlers",
      items: [],
      actions: ["edit", "delete"],
      openDialog: false,
      openDialog2: false,
      openDialog3: false,
      objectToShow: {},
      listToShow1: [],
      listToShow2: []
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("general.actions"),
          width: "10%",
          sortable: false,
          value: "actions"
        },
        {
          text: this.$t("general.name"),
          align: "left",
          value: "name",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("eventListener.eventType.label"),
          align: "left",
          value: "eventTypeId",
          width: 150,
          sortable: true
        },

        {
          text: this.$t("eventListener.eventHandler.params"),
          value: "params",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("eventListener.eventHandler.eventPayloadResolver"),
          value: "eventPayloadResolver",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("eventListener.eventHandler.tasks"),
          value: "tasks",
          width: 150,
          sortable: true
        }
      ];
    }
  },
  methods: {
    async refreshTable() {
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += "&r8sFields=eventTypeId.name";
      return params;
    },
    async afterCallApi(params) {
      return params;
    },
    afterRefresh({ items }) {
      this.items = items;
    },
    async action_activate(actionId, item) {
      switch (actionId) {
        case "edit":
          this.$emit("edit", item);
          break;
        case "delete":
          this.$emit("delete", item);
          break;
      }
    },
    showObject(objectToShow) {
      this.objectToShow = objectToShow;
      this.openDialog = true;
    },
    showList1(listToShow) {
      this.listToShow1 = listToShow;
      this.openDialog2 = true;
    },
    showList2(listToShow) {
      this.listToShow2 = listToShow;
      this.openDialog3 = true;
    }
  }
};
</script>
