<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span>{{ $t("eventListener.eventHandler.params") }}</span>
      <v-spacer />
      <!-- <v-btn text @click="close_click">{{ $t("close") }}</v-btn> -->
    </v-toolbar>

    <v-card-text class="mt-4">
      <SingleObjectView v-if="value" :obj="value" />
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions class="justify-end">
      <v-btn text @click="close_click">{{ $t("close") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SingleObjectView from "@/components/BFFresources/SingleObjectView";

export default {
  name: "GsObjectListDisplay",
  components: {
    SingleObjectView
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    close: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onItemSelected(item) {},
    async close_click() {
      this.$emit("close");
    }
  }
};
</script>
