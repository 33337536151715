<template>
  <v-container class="container" fluid>
    <v-card class="pa-4 d-flex" color="primary" dark>
      <h3>
        {{ $t("eventListener.eventListeners.label") }}
      </h3>
      <v-icon class="ml-auto" @click="add_click">mdi-plus</v-icon>
    </v-card>
    <!-- table -->
    <GsEventListenersTable
      ref="table"
      @edit="edit_click"
      @delete="delete_click"
    />
    <!-- create/edit dialog -->
    <v-dialog :value="dialogVisible" persistent max-width="800px">
      <GsEventListenerEditor
        ref="editor"
        :cancel="cancel_click"
        :save="save_click"
      />
    </v-dialog>
  </v-container>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import GsEventListenersTable from "@/components/EventListener/GsEventListenersTable";
import GsEventListenerEditor from "@/components/EventListener/GsEventListenerEditor";

export default {
  name: "GsEventListeners",
  components: {
    GsEventListenersTable,
    GsEventListenerEditor
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    async action_activate(actionId, item) {
      switch (actionId) {
        case "edit":
          this.edit_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
      }
    },
    async add_click() {
      this.dialogVisible = true;
      this.$nextTick(function() {
        const initData = {
          name: "",
          eventTypeId: "",
          handlerId: "",
          status: "listener.status.active",
          scheduler: "",
          filters: "",
          onBehalfUserId: "",
          onBehalfUserGroup: ""
        };
        this.$refs.editor.add(initData);
      });
    },
    async edit_click(item) {
      this.dialogVisible = true;
      this.$nextTick(function() {
        const initData = {
          listenerId: item.listenerId,
          name: item.name,
          eventTypeId: item.eventTypeId._uri,
          handlerId: item.handlerId._uri,
          status: item.status,
          scheduler: item.scheduler,
          filters: JSON.stringify(item.filters),
          onBehalfUserId: item.onBehalfUserId,
          onBehalfUserGroup: JSON.stringify(item.onBehalfUserGroup)
        };
        this.$refs.editor.edit(initData);
      });
    },
    async cancel_click() {
      this.dialogVisible = false;
    },
    async delete_click(item) {
      let result = confirm(this.$t("delete_modal") + "\n\n" + item.name);
      if (!result) return;

      await callBffAPI({
        url: "/listeners/" + item.listenerId,
        method: "DELETE"
      });
      await this.$refs.table.refreshTable();
    },
    async save_click(sendData) {
      const url = sendData?.listenerId
        ? `/listeners/${sendData.listenerId}`
        : "/listeners";
      const method = sendData?.listenerId ? "PATCH" : "POST";
      const data = { ...sendData, listenerId: undefined };
      try {
        await callBffAPI({ url, method, data });
      } catch (err) {
        console.log(err);
        this.validationResult = err;
      }

      this.dialogVisible = false;
      await this.$refs.table.refreshTable();
    }
  }
};
</script>
