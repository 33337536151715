<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span>{{ $t("eventListener.eventHandler.tasks") }}</span>
      <v-spacer />
      <!-- <v-btn text @click="close_click">{{ $t("close") }}</v-btn> -->
    </v-toolbar>

    <v-card-text class="mt-4">
      <v-list class="pa-0 text-left" style="height: 100%; overflow-y: scroll">
        <v-list-item
          v-for="(item, index) in value"
          :key="index"
          @click="onItemSelected(item)"
        >
          <template v-slot:default="{}">
            <v-list-item-content>
              <v-list-item-title v-text="item.name" />
              <v-list-item-subtitle v-text="item.condition" />
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions class="justify-end">
      <v-btn text @click="close_click">{{ $t("close") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "GsObjectListDisplay",
  components: {},
  props: {
    value: {
      type: Array,
      default: null
    },
    close: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onItemSelected(item) {},
    async close_click() {
      this.$emit("close");
    }
  }
};
</script>
