<template>
  <v-container class="container" fluid>
    <v-card class="pa-4 d-flex" color="primary" dark>
      <h3>
        {{ $t("eventListener.eventTypes.label") }}
      </h3>
      <v-icon class="ml-auto" @click="add_click">mdi-plus</v-icon>
    </v-card>
    <!-- table -->
    <GsEventTypesTable ref="table" @edit="edit_click" @delete="delete_click" />
    <!-- create/edit dialog -->
    <v-dialog :value="dialogVisible" persistent max-width="800px">
      <GsEventTypeEditor
        ref="editor"
        :cancel="cancel_click"
        :save="save_click"
      />
    </v-dialog>
  </v-container>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import GsEventTypesTable from "@/components/EventListener/GsEventTypesTable";
import GsEventTypeEditor from "@/components/EventListener/GsEventTypeEditor";

export default {
  name: "GsEventTypes",
  components: {
    GsEventTypesTable,
    GsEventTypeEditor
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    async action_activate(actionId, item) {
      switch (actionId) {
        case "edit":
          this.edit_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
      }
    },
    async add_click() {
      this.dialogVisible = true;
      this.$nextTick(function() {
        const initData = {
          name: "",
          payload: "",
          filters: ""
        };
        this.$refs.editor.add(initData);
      });
    },
    async edit_click(item) {
      this.dialogVisible = true;
      this.$nextTick(function() {
        const initData = {
          eventTypeId: item.eventTypeId,
          eventTypeCode: item.eventTypeCode,
          name: item.name,
          payload: JSON.stringify(item.payload),
          filters: JSON.stringify(item.filters)
        };
        this.$refs.editor.edit(initData);
      });
    },
    async cancel_click() {
      this.dialogVisible = false;
    },
    async delete_click(item) {
      let result = confirm(this.$t("delete_modal") + "\n\n" + item.name);
      if (!result) return;

      await callBffAPI({
        url: "/event-types/" + item.eventTypeId,
        method: "DELETE"
      });
      await this.$refs.table.refreshTable();
    },
    async save_click(sendData) {
      const url = sendData?.eventTypeId
        ? `/event-types/${sendData.eventTypeId}`
        : "/event-types";
      const method = sendData?.eventTypeId ? "PATCH" : "POST";
      const data = { ...sendData, eventTypeId: undefined };
      try {
        await callBffAPI({ url, method, data });
      } catch (err) {
        console.log(err);
        this.validationResult = err;
      }

      this.dialogVisible = false;
      await this.$refs.table.refreshTable();
    }
  }
};
</script>
