<template>
  <v-container class="container" fluid>
    <v-card class="pa-4 d-flex" color="primary" dark>
      <h3>
        {{ $t("eventListener.eventHandlers.label") }}
      </h3>
      <v-icon class="ml-auto" @click="add_click">mdi-plus</v-icon>
    </v-card>

    <!-- table -->
    <GsEventHandlersTable
      ref="table"
      @edit="edit_click"
      @delete="delete_click"
    />
    <!-- create/edit dialog -->
    <v-dialog :value="dialogVisible" persistent max-width="800px">
      <GsEventHandlerEditor
        ref="editor"
        :cancel="cancel_click"
        :save="save_click"
      />
    </v-dialog>
  </v-container>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import GsEventHandlersTable from "@/components/EventListener/GsEventHandlersTable";
import GsEventHandlerEditor from "@/components/EventListener/GsEventHandlerEditor";

export default {
  name: "GsEventHandlers",
  components: {
    GsEventHandlersTable,
    GsEventHandlerEditor
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    async action_activate(actionId, item) {
      switch (actionId) {
        case "edit":
          this.edit_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
      }
    },
    async add_click() {
      this.dialogVisible = true;
      this.$nextTick(function() {
        const initData = {
          name: "",
          params: "",
          eventPayloadResolver: "",
          eventTypeId: "",
          tasks: ""
        };
        this.$refs.editor.add(initData);
      });
    },
    async edit_click(item) {
      this.dialogVisible = true;
      this.$nextTick(function() {
        const initData = {
          handlerId: item.handlerId,
          eventTypeId: item.eventTypeId._uri,
          name: item.name,
          params: JSON.stringify(item.params),
          eventPayloadResolver: JSON.stringify(item.eventPayloadResolver),
          tasks: JSON.stringify(item.tasks)
        };
        this.$refs.editor.edit(initData);
      });
    },
    async cancel_click() {
      this.dialogVisible = false;
    },
    async delete_click(item) {
      let result = confirm(this.$t("delete_modal") + "\n\n" + item.name);
      if (!result) return;

      await callBffAPI({
        url: "/handlers/" + item.handlerId,
        method: "DELETE"
      });
      await this.$refs.table.refreshTable();
    },
    async save_click(sendData) {
      const url = sendData?.handlerId
        ? `/handlers/${sendData.handlerId}`
        : "/handlers";
      const method = sendData?.handlerId ? "PATCH" : "POST";
      const data = { ...sendData, handlerId: undefined };
      try {
        await callBffAPI({ url, method, data });
      } catch (err) {
        console.log(err);
        this.validationResult = err;
      }

      this.dialogVisible = false;
      await this.$refs.table.refreshTable();
    }
  }
};
</script>
