<template>
  <v-container fluid>
    <div>
      <div class="mb-6 d-flex flex-wrap justify-space-between">
        <div class="flex-grow-1 max-100"><GsEventTypes /></div>
        <div class="flex-grow-1 max-100"><GsEventHandlers /></div>
      </div>
      <div class="max-100"><GsEventListeners /></div>
    </div>
  </v-container>
</template>

<script>
import GsEventTypes from "@/components/EventListener/GsEventTypes";
import GsEventHandlers from "@/components/EventListener/GsEventHandlers";
import GsEventListeners from "@/components/EventListener/GsEventListeners";

export default {
  name: "EventListener",
  components: {
    GsEventTypes,
    GsEventHandlers,
    GsEventListeners
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.max-100 {
  max-width: 100%;
}
</style>
