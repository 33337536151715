var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.editMode ? _vm.$t("eventListener.eventListener.edit") : _vm.$t("eventListener.eventListener.add"))+" ")]),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"my-6",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"mt-6"},[_c('h4',[_vm._v(_vm._s(_vm.$t("name"))+":")]),_c('v-text-field',{attrs:{"dense":"","rules":_vm.rules.name},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('div',{staticClass:"mt-6"},[_c('h4',[_vm._v(_vm._s(_vm.$t("eventListener.eventType.label"))+":")]),_c('GsInfiniteDropdown',{attrs:{"config":_vm.eventTypeSelectionConfig},model:{value:(_vm.formData.eventTypeId),callback:function ($$v) {_vm.$set(_vm.formData, "eventTypeId", $$v)},expression:"formData.eventTypeId"}})],1),(_vm.formData.eventTypeId)?_c('div',{staticClass:"mt-6"},[_c('h4',[_vm._v(_vm._s(_vm.$t("eventListener.eventHandler.label"))+":")]),_c('GsInfiniteDropdown',{attrs:{"config":_vm.handlerSelectionConfig},model:{value:(_vm.formData.handlerId),callback:function ($$v) {_vm.$set(_vm.formData, "handlerId", $$v)},expression:"formData.handlerId"}})],1):_vm._e(),_c('div',{staticClass:"mt-6"},[_c('h4',[_vm._v(_vm._s(_vm.$t("status"))+":")]),_c('v-select',{attrs:{"items":_vm.statuses,"dense":""},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}})],1),_c('div',{staticClass:"mt-6"},[_c('h4',[_vm._v(_vm._s(_vm.$t("eventListener.eventType.scheduler"))+":")]),_c('AceEditor',{attrs:{"lang":"json","theme":"textmate","width":"100%","height":"200px","options":{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            fontSize: 14,
            highlightActiveLine: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
            showPrintMargin: false,
            showGutter: true
          }},on:{"init":_vm.editorInit},model:{value:(_vm.formData.scheduler),callback:function ($$v) {_vm.$set(_vm.formData, "scheduler", $$v)},expression:"formData.scheduler"}})],1),_c('div',{staticClass:"mt-6"},[_c('h4',[_vm._v(_vm._s(_vm.$t("eventListener.eventType.filters"))+":")]),_c('AceEditor',{attrs:{"lang":"json","theme":"textmate","width":"100%","height":"200px","options":{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            fontSize: 14,
            highlightActiveLine: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
            showPrintMargin: false,
            showGutter: true
          }},on:{"init":_vm.editorInit},model:{value:(_vm.formData.filters),callback:function ($$v) {_vm.$set(_vm.formData, "filters", $$v)},expression:"formData.filters"}})],1),_c('div',{staticClass:"mt-6"},[_c('h4',[_vm._v(_vm._s(_vm.$t("eventListener.eventType.onBehalfUserId"))+":")]),_c('v-text-field',{attrs:{"dense":""},model:{value:(_vm.formData.onBehalfUserId),callback:function ($$v) {_vm.$set(_vm.formData, "onBehalfUserId", $$v)},expression:"formData.onBehalfUserId"}})],1),_c('div',{staticClass:"mt-6"},[_c('h4',[_vm._v(_vm._s(_vm.$t("eventListener.eventType.onBehalfUserGroup"))+":")]),_c('AceEditor',{attrs:{"lang":"json","theme":"textmate","width":"100%","height":"200px","options":{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            fontSize: 14,
            highlightActiveLine: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
            showPrintMargin: false,
            showGutter: true
          }},on:{"init":_vm.editorInit},model:{value:(_vm.formData.onBehalfUserGroup),callback:function ($$v) {_vm.$set(_vm.formData, "onBehalfUserGroup", $$v)},expression:"formData.onBehalfUserGroup"}})],1)])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"default"},on:{"click":function($event){return _vm.cancel_click()}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.save_click()}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]),(_vm.errorMessage)?_c('div',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }