<template>
  <div>
    <v-container class="container" fluid>
      <GsDataTable
        ref="table"
        api=""
        endpoint="/listeners"
        :appSetting="appSettingKey"
        :headers="headers"
        :default-sort-by="['name']"
        :default-sort-desc="[false]"
        :beforeCallApi="beforeCallApi"
        :afterCallApi="afterCallApi"
        @after-refresh="afterRefresh"
      >
        <template v-slot:item.actions="{ item }">
          <GsActionsMenu
            :actions="actions"
            :onActivate="actionId => action_activate(actionId, item)"
          />
        </template>

        <template v-slot:item.eventTypeId="{ item }">
          {{ item.eventTypeId._uri }}
        </template>

        <template v-slot:item.handlerId="{ item }">
          {{ item.handlerId._uri }}
        </template>

        <template v-slot:item.filters="{ item }">
          <div
            v-if="item.filters"
            class="link-like"
            @click="showObject(item.filters)"
          >
            ...
          </div>
        </template>

        <template v-slot:item.scheduler="{ item }">
          <div
            v-if="item.scheduler"
            class="link-like"
            @click="showObject(item.scheduler)"
          >
            ...
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip :color="getStatusColor(item.status)" dark>{{
            $t(item.status.split(".").pop())
          }}</v-chip>
        </template>
      </GsDataTable>
    </v-container>
    <!-- object viewer -->
    <v-dialog max-width="600" persistent v-model="openDialog">
      <GsObjectDisplay :value="objectToShow" @close="openDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import { GsDataTable, GsActionsMenu } from "ngt-frontend-core";
import GsObjectDisplay from "@/components/EventListener/FieldEditors/GsObjectDisplay";

export default {
  name: "GsEventListenersTable",
  components: {
    GsDataTable,
    GsActionsMenu,
    GsObjectDisplay
  },
  props: {
    clientIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      appSettingKey: "eventListeners",
      items: [],
      actions: ["edit", "delete"],
      openDialog: false,
      objectToShow: {}
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("general.actions"),
          width: "10%",
          sortable: false,
          value: "actions"
        },
        {
          text: this.$t("general.name"),
          align: "left",
          value: "name",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("eventListener.eventType.label"),
          align: "left",
          value: "eventTypeId",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("eventListener.eventHandler.label"),
          align: "left",
          value: "handlerId",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("eventListener.eventType.filters"),
          value: "filters",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("eventListener.eventListener.scheduler"),
          value: "scheduler",
          width: 150,
          sortable: false,
          align: "center"
        },
        {
          text: this.$t("general.status"),
          value: "status",
          width: "5%",
          sortable: false,
          align: "center"
        }
      ];
    }
  },
  methods: {
    async refreshTable() {
      try {
        // console.log("refreshTable");
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += "&r8sFields=eventTypeId.name,handlerId.name";
      return params;
    },
    async afterCallApi(params) {
      return params;
    },
    afterRefresh({ items }) {
      this.items = items;
    },
    async action_activate(actionId, item) {
      switch (actionId) {
        case "edit":
          this.$emit("edit", item);
          break;
        case "delete":
          this.$emit("delete", item);
          break;
      }
    },
    getStatusColor(status) {
      let statusTail = "";
      status.includes(".")
        ? (statusTail = status.split(".").pop())
        : (statusTail = status);
      // statusTail = "generating" testing a color
      switch (statusTail) {
        case "active":
          return "green";
        case "inactive":
          return "gray";
        default:
          return "gray";
      }
    },
    showObject(objectToShow) {
      this.objectToShow = objectToShow;
      this.openDialog = true;
    }
  }
};
</script>
