<template>
  <v-card>
    <v-card-title>
      {{
        editMode
          ? $t("eventListener.eventType.edit")
          : $t("eventListener.eventType.add")
      }}
    </v-card-title>

    <!-- <v-toolbar color="primary">{{ $t("add_attribute") }}</v-toolbar> -->

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation class="my-6">
        <div class="mt-6">
          <h4>{{ $t("name") }}:</h4>
          <v-text-field dense v-model="formData.name" :rules="rules.name" />
        </div>
        <div class="mt-6">
          <h4>{{ $t("eventListener.eventType.eventTypeCode") }}:</h4>
          <v-text-field
            dense
            v-model="formData.eventTypeCode"
            :rules="rules.eventTypeCode"
          />
        </div>
        <div class="mt-6">
          <h4>{{ $t("eventListener.eventType.payload") }}:</h4>
          <AceEditor
            v-model="formData.payload"
            @init="editorInit"
            lang="json"
            theme="textmate"
            width="100%"
            height="200px"
            :options="{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              fontSize: 14,
              highlightActiveLine: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
              showGutter: true
            }"
          />
        </div>
        <div class="mt-6">
          <h4>{{ $t("eventListener.eventType.filters") }}:</h4>
          <AceEditor
            v-model="formData.filters"
            @init="editorInit"
            lang="json"
            theme="textmate"
            width="100%"
            height="200px"
            :options="{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              fontSize: 14,
              highlightActiveLine: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
              showGutter: true
            }"
          />
        </div>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn class="mr-2" color="default" v-on:click="cancel_click()">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn color="success" v-on:click="save_click()">
        {{ $t("save") }}
      </v-btn>
      <div v-if="errorMessage" style="color: red">{{ errorMessage }}</div>
    </v-card-actions>
  </v-card>
</template>

<script>
const fieldValidators = require("@/managers/fieldValidators");
import AceEditor from "vuejs-ace-editor";

export default {
  name: "GsEventTypeEditor",
  components: {
    AceEditor
  },
  props: {
    cancel: {
      type: Function,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      formData: {
        name: null,
        payload: null,
        filters: null
      },
      rules: {
        name: [fieldValidators.requiredString],
        eventTypeCode: [fieldValidators.requiredString],
        payload: [fieldValidators.requiredString],
        filters: [fieldValidators.requiredString]
      },
      valid: true,
      editMode: false
    };
  },
  computed: {
    errorMessage() {
      return null;
    }
  },
  methods: {
    editorInit: function() {
      require("brace/ext/language_tools");
      require("brace/mode/html");
      require("brace/mode/json");
      require("brace/mode/less");
      require("brace/theme/textmate");
    },
    async add(initData) {
      if (initData) {
        this.formData = this.cloneObj(initData);
      }
      this.valid = true;
      this.editMode = false;
    },
    async edit(initData) {
      // console.log(initData)
      this.formData = this.cloneObj(initData);
      this.valid = true;
      this.editMode = true;
    },
    async cancel_click() {
      this.cancel();
    },
    async save_click() {
      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      let sendData = {
        ...this.formData,
        payload: this.formData?.payload
          ? JSON.parse(this.formData.payload)
          : {},
        filters: this.formData?.filters
          ? JSON.parse(this.formData?.filters)
          : {}
      };
      this.save(sendData);
    }
  }
};
</script>
