<template>
  <v-card>
    <v-card-title>
      {{
        editMode
          ? $t("eventListener.eventListener.edit")
          : $t("eventListener.eventListener.add")
      }}
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation class="my-6">
        <div class="mt-6">
          <h4>{{ $t("name") }}:</h4>
          <v-text-field dense v-model="formData.name" :rules="rules.name" />
        </div>
        <div class="mt-6">
          <h4>{{ $t("eventListener.eventType.label") }}:</h4>
          <GsInfiniteDropdown
            :config="eventTypeSelectionConfig"
            v-model="formData.eventTypeId"
          />
        </div>
        <div class="mt-6" v-if="formData.eventTypeId">
          <h4>{{ $t("eventListener.eventHandler.label") }}:</h4>
          <GsInfiniteDropdown
            :config="handlerSelectionConfig"
            v-model="formData.handlerId"
          />
        </div>
        <div class="mt-6">
          <h4>{{ $t("status") }}:</h4>
          <v-select
            :items="statuses"
            v-model="formData.status"
            dense
          ></v-select>
        </div>
        <div class="mt-6">
          <h4>{{ $t("eventListener.eventType.scheduler") }}:</h4>
          <AceEditor
            v-model="formData.scheduler"
            @init="editorInit"
            lang="json"
            theme="textmate"
            width="100%"
            height="200px"
            :options="{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              fontSize: 14,
              highlightActiveLine: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
              showGutter: true
            }"
          />
        </div>
        <div class="mt-6">
          <h4>{{ $t("eventListener.eventType.filters") }}:</h4>
          <AceEditor
            v-model="formData.filters"
            @init="editorInit"
            lang="json"
            theme="textmate"
            width="100%"
            height="200px"
            :options="{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              fontSize: 14,
              highlightActiveLine: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
              showGutter: true
            }"
          />
        </div>
        <div class="mt-6">
          <h4>{{ $t("eventListener.eventType.onBehalfUserId") }}:</h4>
          <v-text-field dense v-model="formData.onBehalfUserId" />
        </div>
        <div class="mt-6">
          <h4>{{ $t("eventListener.eventType.onBehalfUserGroup") }}:</h4>
          <AceEditor
            v-model="formData.onBehalfUserGroup"
            @init="editorInit"
            lang="json"
            theme="textmate"
            width="100%"
            height="200px"
            :options="{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              fontSize: 14,
              highlightActiveLine: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
              showGutter: true
            }"
          />
        </div>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn class="mr-2" color="default" v-on:click="cancel_click()">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn color="success" v-on:click="save_click()">
        {{ $t("save") }}
      </v-btn>
      <div v-if="errorMessage" style="color: red">{{ errorMessage }}</div>
    </v-card-actions>
  </v-card>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;
const fieldValidators = require("@/managers/fieldValidators");
import AceEditor from "vuejs-ace-editor";
import { GsInfiniteDropdown } from "ngt-frontend-core";

export default {
  name: "GsEventListenerEditor",
  components: {
    AceEditor,
    GsInfiniteDropdown
  },
  props: {
    cancel: {
      type: Function,
      default: () => {}
    },
    save: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      formData: {
        name: null,
        payload: null,
        filters: null
      },
      rules: {
        name: [fieldValidators.requiredString],
        payload: [fieldValidators.requiredString],
        filters: [fieldValidators.requiredString],
        required: [fieldValidators.requiredString]
      },
      valid: true,
      editMode: false
    };
  },
  computed: {
    statuses() {
      return [
        {
          text: "active",
          value: "listener.status.active"
        },
        {
          text: "inactive",
          value: "listener.status.inactive"
        }
      ];
    },
    errorMessage() {
      return null;
    },
    eventTypeSelectionConfig() {
      return {
        dataConnector: callBffAPI,
        dataFields: {
          uri: "/event-types",
          sort: "name:asc",
          fields: "name,eventTypeId",
          search: "name:eq:",
          dataMap: {
            title: "name",
            value: "eventTypeId"
          }
        },
        label: null,
        limit: 50,
        page: 0,
        rules: this.rules.required,
        value: this.formData?.eventTypeId
      };
    },
    handlerSelectionConfig() {
      return {
        dataConnector: callBffAPI,
        dataFields: {
          uri: "/handlers",
          sort: "name:asc",
          fields: "name,handlerId",
          search: "name:eq:",
          additionalFilter: `eventTypeId:eq:${this.formData.eventTypeId}`,
          dataMap: {
            title: "name",
            value: "handlerId"
          }
        },
        label: null,
        limit: 50,
        page: 0,
        rules: this.rules.required,
        value: this.formData?.handlerId
      };
    }
  },
  methods: {
    editorInit: function() {
      require("brace/ext/language_tools");
      require("brace/mode/html");
      require("brace/mode/json");
      require("brace/mode/less");
      require("brace/theme/textmate");
    },
    async add(initData) {
      if (initData) {
        this.formData = this.cloneObj(initData);
      }
      this.valid = true;
      this.editMode = false;
    },
    async edit(initData) {
      // console.log(initData)
      this.formData = this.cloneObj(initData);
      this.valid = true;
      this.editMode = true;
    },
    async cancel_click() {
      this.cancel();
    },
    async save_click() {
      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      let sendData = {
        ...this.formData,
        filters: this.formData?.filters
          ? JSON.parse(this.formData.filters)
          : {},
        scheduler: this.formData?.scheduler
          ? JSON.parse(this.formData.scheduler)
          : undefined,
        onBehalfUserId: this.formData?.onBehalfUserId
          ? this.formData?.onBehalfUserId
          : undefined,
        onBehalfUserGroup: this.formData?.onBehalfUserGroup
          ? JSON.parse(this.formData.onBehalfUserGroup)
          : undefined
      };
      this.save(sendData);
    }
  }
};
</script>
