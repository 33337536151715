<template>
  <div>
    <v-container class="container" fluid>
      <GsDataTable
        ref="table"
        api=""
        endpoint="/event-types"
        :appSetting="appSettingKey"
        :headers="headers"
        :default-sort-by="['name']"
        :default-sort-desc="[false]"
        :beforeCallApi="beforeCallApi"
        :afterCallApi="afterCallApi"
        @after-refresh="afterRefresh"
      >
        <template v-slot:item.actions="{ item }">
          <GsActionsMenu
            :actions="actions"
            :onActivate="actionId => action_activate(actionId, item)"
          />
        </template>
        <template v-slot:item.payload="{ item }">
          <div
            v-if="item.payload"
            class="link-like"
            @click="showObject(item.payload)"
          >
            ...
          </div>
        </template>
        <template v-slot:item.filters="{ item }">
          <div
            v-if="item.filters"
            class="link-like"
            @click="showObject(item.filters)"
          >
            ...
          </div>
        </template>
      </GsDataTable>
    </v-container>
    <!-- object viewer -->
    <v-dialog max-width="600" persistent v-model="openDialog">
      <GsObjectDisplay :value="objectToShow" @close="openDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import { GsDataTable, GsActionsMenu } from "ngt-frontend-core";
import GsObjectDisplay from "@/components/EventListener/FieldEditors/GsObjectDisplay";

export default {
  name: "GsEventTypesTable",
  components: {
    GsDataTable,
    GsActionsMenu,
    GsObjectDisplay
  },
  props: {
    clientIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      appSettingKey: "eventTypes",
      items: [],
      actions: ["edit", "delete"],
      openDialog: false,
      objectToShow: {}
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("general.actions"),
          width: "10%",
          sortable: false,
          value: "actions"
        },
        {
          text: this.$t("general.name"),
          align: "left",
          value: "name",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("eventListener.eventType.eventTypeCode"),
          align: "left",
          value: "eventTypeCode",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("eventListener.eventType.payload"),
          value: "payload",
          width: 150,
          sortable: true
        },
        {
          text: this.$t("eventListener.eventType.filters"),
          value: "filters",
          width: 150,
          sortable: true
        }
      ];
    }
  },
  methods: {
    async refreshTable() {
      try {
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      return params;
    },
    async afterCallApi(params) {
      return params;
    },
    afterRefresh({ items }) {
      this.items = items;
    },
    async action_activate(actionId, item) {
      switch (actionId) {
        case "edit":
          this.$emit("edit", item);
          break;
        case "delete":
          this.$emit("delete", item);
          break;
      }
    },
    showObject(objectToShow) {
      this.objectToShow = objectToShow;
      this.openDialog = true;
    }
  }
};
</script>
